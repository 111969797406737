import React from "react";
import styles from "../styles/testimonials.module.scss";

export default function Testimonials() {
  return (
    <div>
      <h1 className={ `header` }>Testimonials</h1>
      <div className={ styles.testimonials }>

        {/* Testimonial #1 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                Matt and Amy have been amazing developers to work with. They're thorough in their work, clear and responsive in communication, and you can tell that they genuinely care about the software they create.
              </p>
              <p>
                In their time spent working with us, they built a highly interactive and functional website for us to train our clients remotely and we have no doubt that it will be a hit with live users once we launch.
              </p>
              <p>
                We're excited to keep working with them in the future and I'd recommend them highly for any kind of software or development position.
              </p>
            </div>
            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Alex Bryce
            </div>
            <div className={ styles.role }>
              <b>Personal Trainer &amp; Co-Founder</b> &mdash; <a
                href="https://electrumperformance.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>Electrum Performance</a>
            </div>
          </div>
        </div>


        {/* Testimonial #2 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                Anyone who has read my blog posts might know that I have a strong aversion to paying for services. Well, I can say that for me, ProfitKit is 100% worth it.
              </p>

              <p>
                I've tried many subscription analytics solutions and they all spewed out different numbers. Worst yet, their customer service would just tell me I'm out of luck because I use coupons a certain way.
              </p>
              
              <p>
                Working with Matt &amp; Amy has been awesome because they have made their product customizable to everyone's unique needs and I now have full confidence in the numbers I see on my dashboard.
              </p>
            </div>

            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Jen Yip
            </div>
            <div className={ styles.role }>
              <b>Founder of <a
                href="https://lunchmoney.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="link">
                Lunch Money</a></b> &mdash; <i>for work on </i> <a
                href="https://www.profitkit.io"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>ProfitKit</a>
            </div>
          </div>
        </div>

        {/* Testimonial #3 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                This solved a HUGE problem we had. We are an agency with clients on a monthly billing cycle but had no way to forecast our upcoming revenue on a weekly basis.
              </p>
              <p>
                Our attempt at doing it manually using a spreadsheet was cumbersome and inaccurate. ProfitKit saved us hours each week and ensured the accuracy of our weekly revenue projections.
              </p>
            </div>

            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Kevin Wilke
            </div>
            <div className={ styles.role }>
              <b>Co-Founder of <a 
                href="https://www.provenmarketingsolutions.co/"
                target="_blank"
                rel="noopener noreferrer"
                className="link">
                Proven Marketing Solutions</a>
                </b> &mdash; <i>for work on </i> <a
                href="https://www.profitkit.io"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>ProfitKit</a>
            </div>
          </div>
        </div>


        {/* Testimonial #4 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                Folks, check out ProfitKit, a must need tool for any SaaS business. At Remote Leaf, we faced a huge problem with user payments failing, and I've been manually reminding users to update their subscriptions. Then I found out about ProfitKit through Amy on Indiehackers. Amy was super helpful in setting up the payment recovery integration and also wrote custom development code to fit my product’s specific needs.
              </p>

              <p>
                Highly recommend it!
              </p>
            </div>

            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Abinaya
            </div>
            <div className={ styles.role }>
              <b>Founder of <a
                href="https://remoteleaf.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                >
                Remote Leaf</a> 
                </b> &mdash; <i>for work on </i> <a
                href="https://www.profitkit.io"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>ProfitKit</a>
            </div>
          </div>
        </div>

        {/* Testimonial #5 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                Only a few days after using ProfitKit, the automatic emails to unpaid subscribers reactivated 4 customers for $40 in MRR and collected $160 in past due payments. I can't think of another piece of software I've used that has paid for itself so directly.
              </p>
            </div>

            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Chad Sakonchick
            </div>
            <div className={ styles.role }>
              <b>Co-Founder of <a
                href="https://betterlegal.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link">
                BetterLegal</a></b> &mdash; <i>for work on </i> <a
                href="https://www.profitkit.io"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>ProfitKit</a>
            </div>
          </div>
        </div>
      
        {/* Testimonial #6 */}
        <div className={ styles.testimonial }>
          <div className={ styles.testimonial__body }>
            <div className={ styles.text}>
              <p>
                Amy, thank you so much for stepping in to help with the Forget the Funnel site like you have and putting all of this documentation together. Again, we are more appreciative of this help than you could possibly know.
              </p>

              <p>
                We SUUUUPER appreciate your continued generosity + advice on hiring! Between the Memberstack team themselves + their community of experts, we've found someone to assist us and take over. Thank you so so much for taking the time to do this.
              </p>
            </div>

            <div className={ styles.quote__circle }>
              <div className={ styles.quote}>&#8221;</div>
            </div>
          </div>

          <div className={ styles.testimonial__footer }>
            <div className={ styles.name }>
              Claire Suellentrop &amp; Georgiana Laudi
            </div>
            <div className={ styles.role }>
              <b>Professional Marketers &amp; Founders</b> &mdash; <a
                href="https://www.forgetthefunnel.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={ styles.label }>Forget the Funnel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
